export const environment = {
  GRAPHQL_URL: 'https://ao32wy6ehe.execute-api.eu-west-1.amazonaws.com/staging/graphql',
  AWS_PROJECT_REGION: 'eu-west-1',
  AWS_COGNITO_REGION: 'eu-west-1',
  AWS_USER_POOLS_ID: 'eu-west-1_EbFKO0G83',
  AWS_USER_POOLS_WEB_CLIENT_ID: '2pljctfeedjk356db6f2qdd0km',
  OAUTH_DOMAIN: 'staging-radar.auth.eu-west-1.amazoncognito.com',
  AMPLIFY_REDIRECT_SIGN_IN: 'https://staging.voyagu.com/login',
  AMPLIFY_REDIRECT_SIGN_OUT: 'https://staging.voyagu.com/login',
  PORTAL_URL: 'https://staging-q.bcconsolidator.com',
  PORTAL_BUILD_QUERY_KEY: '6a004d54ad43#14',
  TWILIO_SID: 'AP6c509d3040619fed8a148b904676bfe1',
  SEARCH_API: 'https://es4ky7tw7mm7e6plmyor2vbm6m0ghkne.lambda-url.eu-west-1.on.aws',
  COMMIT_ID: '6e0bc88b0dd5c03dd5758bba7f1e3bf6ad0220d8',
  TRACKING_URL: 'https://staging-tr.businessclassconsolidator.com',
  RINGCENTRAL_CLIENT_ID: '3BgVD4M3lwRdOOCb0Cxv5x',
  RINGCENTRAL_CLIENT_SECRET: 'XrqO0Lav78KamiNZXZEOtv3VOxEygiK1ndVQWp2qzmgs',
  RINGCENTRAL_SERVER: 'https://platform.ringcentral.com',
  RINGCENTRAL_REDIRECT_URI: 'https://ao32wy6ehe.execute-api.eu-west-1.amazonaws.com/staging/ring_central_token',
  production: true,
  
};
